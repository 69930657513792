// React
import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import backend from "../../services/backend";
import ConversationDetailsDataGrid from "./ConversationDetailsDataGrid";

// Third Party
import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Typography,
  Popover,
  CircularProgress,
  IconButton,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import DownloadIcon from "@mui/icons-material/Download";
import RecordingPlaybackModal from "../Modals/recordingPlaybackModal/RecordingPlaybackModal";
import EmailTranscriptView from "../emailTranscriptView/EmailTranscriptView";
import ChatTranscriptView from "../chatTranscriptView/ChatTranscriptView";
import WaitingModal from "../Modals/waitingModal/WaitingModal";
import AlertModal from "../Modals/alertModal/AlertModal";
import { DataGrid, GridActionsCellItem, useGridApiRef } from "@mui/x-data-grid";
import {
  handleTableFilterChange,
  handleTableColumnVisibilityChange,
  handleTablePaginationChange,
  handleTableSortingChange,
  mapRecordings,
} from "../../components/utilities/table-utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import constants from "../../constants";
import { TextareaAutosize } from "@mui/material";
import { fontWeight } from "@mui/system";

const defaultInitialJobsTableState = {
  columns: {
    columnVisibilityModel: {},
  },
  sorting: {
    sortModel: [
      {
        field: "dateSequence",
        sort: "asc",
      },
    ],
  },
  filter: {
    filterModel: {
      items: [],
    },
  },
  pagination: {
    paginationModel: {
      pageSize: 25,
      page: 0,
    },
  },
};

const RecordingForm = ({ fields, setFields, onCloseDialog }) => {
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false);
  const [error, setError] = useState();

  const [recordings, setRecordings] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [isRecordingPlaybackModalOpen, setIsRecordingPlaybackModalOpen] =
    useState(false);
  const [recordingPlayback, setRecordingPlayback] = useState();

  const isMounted = React.useRef(true);
  const gridRef = useGridApiRef();
  const evalGridRef = useGridApiRef();
  const tablePrefId = "recordings";
  const evalTablePrefId = "recordings";
  const [tablePreferences, setTablePreferences] = React.useState(
    defaultInitialJobsTableState
  );
  const [evalDisabled, setEvalDisabled] = useState(false);
  const [role, setRole] = useState();
  const [isStillProcessing, setIsStillProcessing] = useState(true);

  const [recordingURL, setRecordingURL] = useState(null);

  const [isDownloading, setIsDownloading] = useState(false);
  const [preFetchedBlob, setPreFetchedBlob] = useState(null);

  const [wavRecording, setWavRecording] = useState([]);
  const [opusRecording, setOpusRecording] = useState([]);


  useEffect(() => {
    const preFetchRecording = async () => {
      if (!recordingURL) return; // Check if recordingURL is not null

      try {
        const response = await fetch(recordingURL);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        setPreFetchedBlob(blob);
      } catch (error) {
        console.error("Error pre-fetching the file:", error);
      }
    };

    preFetchRecording();
  }, [recordingURL]);

  const canDownload = () => {
    const normalizedRole = role.toLowerCase();
    return ["admin", "supervisorwithdownload", "ogc_admin"]
      .map((r) => r.toLowerCase())
      .includes(normalizedRole);
  };

  const handleDownload = async () => {
    setIsDownloading(true);

    try {
      const blob = preFetchedBlob ? preFetchedBlob : await fetchRecordingBlob();
      downloadBlob(blob, `${fields.conversation_id}.wav`);
    } catch (error) {
      console.error("Error during the download process:", error);
    } finally {
      setIsDownloading(false);

      try {
        await updateRecordingStatus();
      } catch (apiError) {
        console.error("Error updating recording status:", apiError);
      }
    }
  };

  const fetchRecordingBlob = async () => {
    const response = await fetch(recordingURL);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  };

  const updateRecordingStatus = async () => {
    const usertoken = sessionStorage.getItem(constants.GC_TOKEN);
    const apiResponse = await backend.getRecordingURLStatus(
      usertoken,
      fields.conversation_id,
      true
    );
    console.log(apiResponse); // Handle the apiResponse here
  };

  const downloadBlob = (blob, filename) => {
    const blobUrl = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.style.display = "none";
    anchor.href = blobUrl;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    window.URL.revokeObjectURL(blobUrl);
    document.body.removeChild(anchor);
  };

  const toggleRecordingPlaybackModal = () =>
    setIsRecordingPlaybackModalOpen(!isRecordingPlaybackModalOpen);
  React.useEffect(() => {
    setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
    console.log("Conversation Details role : " + role);
    const fetchRecordingStatus = async () => {
      let processedRecordings = [];
      if (fields.recordings) {
        let recordingList = [];
        recordingList = fields.recordings;
        setRecordings(mapRecordings(recordingList));

        setWavRecording(recordingList.map(recording => ({
          recordingId: recording.recording_id,
          s3url: recording.url
        })))

        await getEvaluations();
        fields.recordings.forEach(async (recording) => {
          if (recording.status != "COMPLETED") {
            setIsStillProcessing(true);
            console.log(
              `fetchProcessedRecordings:: Recording Response details ${JSON.stringify(
                recording
              )}`
            );

            setRecordingURL(recording.url);

            let apiCallSuccess = false;
            do {
              let usertoken = sessionStorage.getItem(constants.GC_TOKEN);
              let s3path = `${recording.relative_path}.opus`;
              console.log(
                `fetchProcessedRecordings:: s3path is ${s3path} and it is type of ${typeof s3path}`
              );
              console.log(
                `fetchProcessedRecordings:: We are about to invoke API call to retrive processed Recordings with relative path as ${s3path}`
              );
              try {
                let apiResponse = await backend.getRecordingURLStatus(
                  usertoken,
                  s3path
                );
                console.log(
                  `fetchProcessedRecordings:: API response for recording URL Status is ${JSON.stringify(
                    apiResponse
                  )}`
                );
                if (
                  apiResponse.convertionStatus &&
                  apiResponse.convertionStatus == "COMPLETED"
                ) {
                  apiCallSuccess = true;
                  recording.status = apiResponse.convertionStatus;
                  recording.url = apiResponse.recURL;
                } else {
                  apiCallSuccess = false;
                }
              } catch (exception) {
                console.log(
                  `fetchProcessedRecordings::Got Exception in get recording URL Status`
                );
                console.log(exception);
                apiCallSuccess = false;
              }

              if (apiCallSuccess == false) {
                console.log(
                  `fetchProcessedRecordings:: will check recordings status after 10 seconds ${new Date()}`
                );
                await sleepFor(10 * 1000);
                console.log(
                  `fetchProcessedRecordings:: will retry now ${new Date()}`
                );
              }
            } while (apiCallSuccess == false);

            console.log(
              `Processed Recordings are ${JSON.stringify(recording)}`
            );
            processedRecordings.push(recording);
            console.log(
              `Processed Recordings are ${JSON.stringify(processedRecordings)}`
            );
            if (processedRecordings && processedRecordings.length > 0) {
              setRecordings(processedRecordings);
              setOpusRecording(processedRecordings.map(recording => ({
                recordingId: recording.recording_id,
                s3url: recording.url
              })));

              setIsStillProcessing(false);
            } else {
              console.log(`We dont have any processed recordings`);
            }
          } else {
            console.log(`recording.status is COMPLETED`);
          }
        });
      }
    };
    fetchRecordingStatus();
  }, []);

  // Dismissal

  const onCancelRecording = React.useCallback(() => {
    return onCloseDialog({}, "cancel");
  }, [onCloseDialog]);

  const handleMainButtonClick = async (format) => {
    try {

      let recordings = format === 'opus' ? opusRecording : wavRecording;

      if (recordings.length === 0) {
        throw new Error('No recordings available to download');
      }

      let payload = {
        conversationId: fields.conversation_id,
        type: format,
        recordings: recordings
      };

      console.log("Payload:", payload);

      let response = await backend.downloadRecordings(sessionStorage.getItem(constants.GC_TOKEN), payload);
      let s3Url = response.s3URL;

      console.log("S3 URL:", s3Url);

      // Fetch the file from the S3 URL
      const responseFile = await fetch(s3Url);

      if (!responseFile.ok) {
        throw new Error(`Failed to download file from ${s3Url}`);
      }

      const blob = await responseFile.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fields.conversation_id}.zip`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };



  const RecordingView = ({ recordings }) => {
    console.log("Recording View : ", recordings);
    if (!recordings || recordings.length === 0) {
      return <Typography variant="body1">No recordings available.</Typography>;
    }

    return (
      <Box>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Recordings
            </Typography>
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              {canDownload() && (
                <>
                  <Grid item>
                    <Button variant="contained" onClick={() => handleMainButtonClick('opus')}>
                      Download OPUS
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant="contained" onClick={() => handleMainButtonClick('wav')}>
                      Download WAV
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

        </Grid>
        <DataGrid
          autoHeight
          pageSize={5}
          rows={recordings}
          columns={columns}
          getRowHeight={() => "auto"}
        />
      </Box>
    );
  };

  const formatDuration = (seconds) => {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  };

  const getEvalTime = (params) => {
    //return (moment(params.row.start_time).format("MMM Do YYYY, h:mm:ss a"));
    return new Date(params.row.eval_local_datetime1).toLocaleString();
  };
  const getStartTime = (params) => {
    //return (moment(params.row.start_time).format("MMM Do YYYY, h:mm:ss a"));
    return new Date(params.row.start_time).toLocaleString();
  };
  const getEndTime = (params) => {
    //return (moment(params.row.end_time).format("MMM Do YYYY, h:mm:ss a"));
    return params.row.end_time
      ? new Date(params.row.end_time).toLocaleString()
      : "";
  };
  const getDuration = (params) => {
    return new Date(params.row.duration * 1000).toISOString().substr(11, 8);
  };

  const getAgentName = (params) => {
    return params.row.agent_name;
  };

  const getName = (params) => {
    return params.row.username;
  };
  const getFormDetails = (params) => {
    return params.row.formdetails
      .filter((f) => f.answer_title1 != null || f.form_component_score != null)
      .map((f) => {
        return `${f.form_component_title}:${f.answer_title1 != null ? f.answer_title1 : f.form_component_score
          },${f.form_component_score != null
            ? f.form_component_score
            : f.answer_title1
          }\n`;
      })
      .join("");
  };
  const defaultColumnWidth = 150;
  const evalColumns = [
    // {
    //   field: "call_id_string1",
    //   headerName: "CALL ID",
    //   type: "string",
    //   width: defaultWidthColumns + 50,
    //   renderHeader: (params) => (
    //     <Typography
    //       component="label"
    //       variant="body2"
    //       sx={{ whiteSpace: "normal" }}
    //     >
    //       CALL ID
    //     </Typography>
    //   ),
    // },
    {
      field: "evaluatee_name1",
      headerName: "EVALUATEE NAME",
      type: "string",
      width: defaultColumnWidth + 50,
      renderHeader: (params) => (
        <Typography
          component="label"
          variant="body2"
          sx={{ whiteSpace: "normal" }}
        >
          EVALUATEE NAME
        </Typography>
      ),
    },
    {
      field: "evaluator_name1",
      headerName: "EVALUATOR NAME",
      type: "string",
      width: defaultColumnWidth + 50,
      renderHeader: (params) => (
        <Typography
          component="label"
          variant="body2"
          sx={{ whiteSpace: "normal" }}
        >
          EVALUATOR NAME
        </Typography>
      ),
    },
    {
      field: "eval_local_datetime1",
      headerName: "EVALUATION DATE",
      type: "string",
      width: defaultColumnWidth + 50,
      renderHeader: (params) => (
        <Typography
          component="label"
          variant="body2"
          sx={{ whiteSpace: "normal" }}
        >
          EVALUATION DATE
        </Typography>
      ),
      valueGetter: (params) => getEvalTime(params),
    },
    {
      field: "form1",
      headerName: "FORM NAME",
      type: "string",
      width: defaultColumnWidth + 150,
      renderHeader: (params) => (
        <Typography
          component="label"
          variant="body2"
          sx={{ whiteSpace: "normal" }}
        >
          FORM NAME
        </Typography>
      ),
    },

    {
      field: "formdetails",
      headerName: "FORM DETAILS",
      type: "string",
      width: defaultColumnWidth + 200,
      renderCell: (params) => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const handleClick = (event) => {
          setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? "simple-popover" : undefined;

        return (
          <React.Fragment>
            <Button
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
            >
              View Details
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box p={2}>
                {params.value.map((detail, index) =>
                  detail.answer_title1 || detail.form_component_score ? (
                    <Box key={index} mb={2}>
                      <Typography variant="subtitle1" gutterBottom>
                        {detail.form_component_title}
                      </Typography>
                      {detail.answer_title1 && (
                        <Typography variant="body2">
                          Answer: {detail.answer_title1}
                        </Typography>
                      )}
                      {detail.form_component_score && (
                        <Typography variant="body2">
                          Score: {detail.form_component_score}
                        </Typography>
                      )}
                    </Box>
                  ) : null
                )}
              </Box>
            </Popover>
          </React.Fragment>
        );
      },
      valueGetter: (params) =>
        params.row.formdetails.filter(
          (detail) =>
            detail.answer_title1 !== null ||
            detail.form_component_score !== null
        ),
    },
  ];
  const columns = [
    {
      field: "start_time",
      headerName: "START TIME",
      type: "string",
      width: defaultColumnWidth + 50,
      renderHeader: (params) => (
        <Typography
          component="label"
          variant="body2"
          sx={{ whiteSpace: "normal" }}
        >
          START TIME
        </Typography>
      ),
      valueGetter: (params) => getStartTime(params),
    },
    {
      headerName: "End Time",
      field: "end_time",
      width: defaultColumnWidth + 50,
      renderHeader: (params) => (
        <Typography
          component="label"
          variant="body2"
          sx={{ whiteSpace: "normal" }}
        >
          END TIME
        </Typography>
      ),
      valueGetter: (params) => getEndTime(params),
    },
    {
      headerName: "Agent Name",
      field: "agent_name",
      width: defaultColumnWidth + 50,
      renderHeader: (params) => (
        <Typography
          component="label"
          variant="body2"
          sx={{ whiteSpace: "normal" }}
        >
          AGENT NAME
        </Typography>
      ),
      valueGetter: (params) => getAgentName(params),
    },
    {
      headerName: "Duration",
      field: "duration",
      width: defaultColumnWidth + 50,
      renderHeader: (params) => (
        <Typography
          component="label"
          variant="body2"
          sx={{ whiteSpace: "normal" }}
        >
          DURATION
        </Typography>
      ),
      valueGetter: (params) => getDuration(params),
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: defaultColumnWidth * 2,
      headerAlign: "center",
      renderCell: (params) => {
        if (isStillProcessing) {
          return (
            <Box display="flex" justifyContent="center">
              <CircularProgress size={24} />
            </Box>
          );
        } else {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <audio
                autoPlay={false}
                title={`Recording ${params.row.recording_id}`}
                controls
                src={params.row.url}
                style={{ flexGrow: 1 }}
                controlsList="nodownload"
              />
              {canDownload(params.role) && (
                <Tooltip title="Download">
                  <IconButton
                    onClick={handleDownload}
                    size="large"
                    disabled={isDownloading}
                  >
                    {isDownloading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <DownloadIcon />
                    )}
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          );
        }
      },
    },
  ];

  const getEvaluations = async () => {
    if (!evalDisabled) {
      try {
        let usertoken = sessionStorage.getItem(constants.GC_TOKEN);
        const searchCriteria = new Map();
        searchCriteria.set(
          constants.SEARCH_PARAM_CONTACT_ID,
          fields.conversation_id
        );
        let apiResponse = await backend.getEvaluationDetails(
          usertoken,
          Object.fromEntries(searchCriteria)
        );
        console.log(
          `getEvaluationDetails:: API response for recording URL Status is ${JSON.stringify(
            apiResponse
          )}`
        );
        setEvaluations(apiResponse.evaluations);
      } catch (exception) {
        console.log(
          `getEvaluationDetails::Got Exception in getting evaluation details`
        );
        console.log(exception);
      }
    }
  };

  return (
    <React.Fragment>
      <Box>
        {/*<Grid container rowSpacing={1} columnSpacing={{ xs: 12, md: 12 }}>
                    <Grid item xs={6} md={6}>
                        <span className="conversations-details-header-text">Conversation Details - {fields && fields.conversation_id && fields.conversation_id}</span>
                    </Grid>
    </Grid>*/}
        <>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 2, md: 3 }}>
            <Grid item xs={6} md={3}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="subtitle1"
                component="div"
              >
                Start Date
              </Typography>
              <Typography variant="subtitle2">
                {fields &&
                  fields.conversation_start &&
                  moment(fields.conversation_start).format(
                    "MMM Do YYYY, h:mm:ss a"
                  )}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="subtitle1"
                component="div"
              >
                End Date
              </Typography>
              <Typography variant="subtitle2">
                {fields &&
                  fields.conversation_end &&
                  moment(fields.conversation_end).format(
                    "MMM Do YYYY, h:mm:ss a"
                  )}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="subtitle1"
                component="div"
              >
                ANI
              </Typography>
              <Typography variant="subtitle2">
                {fields && fields.ani}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="subtitle1"
                component="div"
              >
                Agent
              </Typography>
              <Typography variant="subtitle2">
                {fields && fields.agent_name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container rowSpacing={2} columnSpacing={{ xs: 2, md: 3 }}>
            <Grid item xs={6} md={3}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="subtitle1"
                component="div"
              >
                Division
              </Typography>
              <Typography variant="subtitle2">
                {fields && fields.division_name}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="subtitle1"
                component="div"
              >
                Handle Duration
              </Typography>
              <Typography variant="subtitle2">
                {fields &&
                  fields.handle_duration &&
                  formatDuration(fields.handle_duration)}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="subtitle1"
                component="div"
              >
                Direction
              </Typography>
              <Typography variant="subtitle2">
                {fields && fields.originating_direction}
              </Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="subtitle1"
                component="div"
              >
                Talk & Hold
              </Typography>
              <Typography variant="subtitle2">
                {fields && fields.hold_time && formatDuration(fields.hold_time)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container rowSpacing={2} columnSpacing={{ xs: 2, md: 3 }}>
            <Grid item xs={6} md={3}>
              <Typography
                sx={{ fontWeight: "bold" }}
                variant="subtitle1"
                component="div"
              >
                Media Type
              </Typography>
              <Typography variant="subtitle2">
                {fields && fields.media_type}
              </Typography>
            </Grid>
          </Grid>
        </>
        <br />

        <RecordingView recordings={recordings} />
        {!evalDisabled && (
          <Box>
            <Typography sx={{ fontWeight: "bold" }} variant="h6">
              Evaluations
            </Typography>
            <DataGrid
              style={{ display: "grid", width: "auto" }}
              apiRef={evalGridRef}
              key={evalTablePrefId}
              autoHeight
              pageSizeOptions={[5, 10, 15, 20]}
              rows={evaluations}
              columns={evalColumns}
              getRowId={(row) => row.call_id_string1}
              getRowHeight={() => "auto"}
              filterModel={{
                items: [],
              }}
              sortingOrder={["desc", "asc"]}
              onFilterModelChange={(model, details) =>
                handleTableFilterChange(
                  model,
                  details,
                  tablePreferences,
                  setTablePreferences,
                  evalTablePrefId
                )
              }
              sortModel={tablePreferences.sorting.sortModel}
              onSortModelChange={(model, details) =>
                handleTableSortingChange(
                  model,
                  details,
                  tablePreferences,
                  setTablePreferences,
                  evalTablePrefId
                )
              }
              columnVisibilityModel={
                tablePreferences.columns.columnVisibilityModel
              }
              onColumnVisibilityModelChange={(model, details) =>
                handleTableColumnVisibilityChange(
                  model,
                  details,
                  tablePreferences,
                  setTablePreferences,
                  evalTablePrefId
                )
              }
              paginationModel={tablePreferences.pagination.paginationModel}
              onPaginationModelChange={(model, details) =>
                handleTablePaginationChange(
                  model,
                  details,
                  tablePreferences,
                  setTablePreferences,
                  evalTablePrefId
                )
              }
            />

            <Typography sx={{ fontWeight: "bold" }} variant="h6">
              Conversation Details
            </Typography>
            <ConversationDetailsDataGrid data={fields} />
          </Box>
        )}

        <div id="main">
          <WaitingModal
            isOpen={isWaitingModalOpen}
            header="Loading conversation details"
          />
          <AlertModal
            isOpen={!!error}
            header="Error"
            toggle={() => {
              setError(undefined);
            }}
            body={error}
          />

          <RecordingPlaybackModal
            isOpen={isRecordingPlaybackModalOpen}
            toggle={() => {
              toggleRecordingPlaybackModal();
            }}
            recording={recordingPlayback}
          />
        </div>
      </Box>
    </React.Fragment>
  );
};

const sleepFor = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
export default RecordingForm;
