import utils from "./utils";
import config from "../config";

function checkStatus(response) {
  if (!response.ok) {
    throw new Error(`HTTP ${response.status} - ${response.statusText}`);
  }
  return response;
}

// TODO: Clarify All URI resources with backend dev

export const getRecording = async (id) => {
  const uri = `${config.endpoints.backend}/recordings/${id}`;
  const headers = {
    env: utils.getEnvironment(),
  };

  return fetch(uri, { headers })
    .then((response) => checkStatus(response) && response.arrayBuffer())
    .then((buffer) => {
      return { id, buffer };
    })
    .catch((error) => {
      const err = { id, error };
      throw err;
    });
};

export const getConversationsSettings = async (token) => {
  const uri = `${config.endpoints.backend}/settings/conversations`;
  return utils.fetchWrapper(uri, {
    method: "GET",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
  });
};

export const updateConversationsSettings = async (
  token,
  conversationColumns
) => {
  const uri = `${config.endpoints.backend}/settings/conversations`;
  return utils.fetchWrapper(uri, {
    method: "PUT",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
    body: JSON.stringify({ conversationColumns }),
  });
};

export const getConversations = async (token, searchCriteria) => {
  const uri = `${config.endpoints.backend}/search`;
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
    body: JSON.stringify({
      searchCriteria,
    }),
  });
};

export const getIPFXConversations = async (token, searchCriteria) => {
  let queryString = "?";
  if (utils.isIterable(searchCriteria)) {
    for (const [key, value] of searchCriteria) {
      if (
        key === "selectedClients" ||
        key === "selectedChannels" ||
        key === "selectedQueues"
      )
        continue;
      if (Array.isArray(value)) {
        for (const item of value) if (item) queryString += `${key}=${item}&`;
      } else queryString += `${key}=${value}&`;
    }
  }
  const uri = `${config.endpoints.backend}/ipfx/search`;
  console.log("fetching uri:", uri);
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
    body: JSON.stringify({
      searchCriteria,
    }),
  });
};

export const exportIPFXConversations = async (token, searchCriteria) => {
  let queryString = "?";
  if (utils.isIterable(searchCriteria)) {
    for (const [key, value] of searchCriteria) {
      if (
        key === "selectedClients" ||
        key === "selectedChannels" ||
        key === "selectedQueues"
      )
        continue;
      if (Array.isArray(value)) {
        for (const item of value) if (item) queryString += `${key}=${item}&`;
      } else queryString += `${key}=${value}&`;
    }
  }
  const uri = `${config.endpoints.backend}/ipfx/search?export=true`;
  console.log("fetching uri:", uri);
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
    body: JSON.stringify({
      searchCriteria,
    }),
  });
};


export const getEvaluationDetails = async (token, searchCriteria) => {
  const uri = `${config.endpoints.backend}/eval/search`;
  console.log("fetching uri:", uri);
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
    body: JSON.stringify({
      searchCriteria,
    }),
  });
};

const getRecordingURLStatus = async (token, relativePath, queryString) => {
  let uri;
  if (queryString) {
    uri = `${config.endpoints.backend}/recordingstatus?download=true`;
  } else {
    uri = `${config.endpoints.backend}/recordingstatus`;
  }
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
    body: JSON.stringify({
      relativePath,
    }),
  });
};

export const exportConversations = async (token, searchCriteria) => {
  let queryString = "?";
  if (utils.isIterable(searchCriteria)) {
    for (const [key, value] of searchCriteria) {
      if (
        key === "selectedClients" ||
        key === "selectedChannels" ||
        key === "selectedQueues"
      )
        continue;
      if (Array.isArray(value)) {
        for (const item of value) if (item) queryString += `${key}=${item}&`;
      } else queryString += `${key}=${value}&`;
    }
  }
  const uri = `${config.endpoints.backend}/export`;
  console.log("fetching uri:", uri);
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
    body: JSON.stringify({
      searchCriteria,
    }),
  });
};

export const getConversation = async (token, conversationId) => {
  const uri = `${config.endpoints.backend}/conversation?conversation_id=${conversationId}`;
  console.log("fetching uri:", uri);
  return utils.fetchWrapper(uri, {
    method: "GET",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
  });
};

export const createUser = async (token, user) => {
  const uri = `${config.endpoints.backend}/users`;
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
    body: JSON.stringify({
      //"channels": user.channelsSelected,
      //"userid": user.user.value,
      email: user.emailAddressEntered,
      role: user.role,
      disabled: user.disabled,
      //"region": user.region,
      divisions: user.divisions,
    }),
  });
};

export const updateUser = async (token, id, user) => {
  const uri = `${config.endpoints.backend}/users/${id}`;
  console.log(`updateUser input is ${JSON.stringify(user)}`);
  return utils.fetchWrapper(uri, {
    method: "PUT",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
    body: JSON.stringify({
      //"channels": user.channelsSelected,
      userid: user.userid,
      email: user.emailAddressEntered,
      role: user.role,
      disabled: user.disabled,
      //"region": user.region,
      divisions: user.divisions,
    }),
  });
};

export const getUsersMe = async (token) => {
  const uri = `${config.endpoints.backend}/users/me`;
  return utils.fetchWrapper(uri, {
    method: "GET",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
  });
};

export const getUserListAll = async (token) => {
  const uri = `${config.endpoints.backend}/users`;
  return utils.fetchWrapper(uri, {
    method: "GET",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
  });
};

export const getUserByID = async (token, id) => {
  const uri = `${config.endpoints.backend}/users/${id}`;
  return utils.fetchWrapper(uri, {
    method: "GET",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
  });
};

export const getUsersListSearch = async (token, searchCriteria) => {
  let queryString = "?";
  if (utils.isIterable(searchCriteria)) {
    for (const [key, value] of searchCriteria) {
      if (Array.isArray(value)) {
        for (const item of value) if (item) queryString += `${key}=${item}&`;
      } else queryString += `${key}=${value}&`;
    }
  }
  const uri = `${config.endpoints.backend}/users${queryString}`;
  console.log("fetching uri:", uri);
  return utils.fetchWrapper(uri, {
    method: "GET",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
  });
};
export const getAuditAccessReport = async (token, searchCriteria) => {
  let queryString = "?";

  console.log("Search Criteria: " + JSON.stringify(searchCriteria));

  if (searchCriteria && typeof searchCriteria === "object") {
    for (const [key, value] of Object.entries(searchCriteria)) {
      if (Array.isArray(value)) {
        for (const item of value) {
          if (item)
            queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
              item
            )}&`;
        }
      } else {
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
          value
        )}&`;
      }
    }
  }

  if (queryString.endsWith("&")) {
    queryString = queryString.slice(0, -1);
  }

  console.log(queryString);

  const uri = `${config.endpoints.backend}/audit/access${queryString}`;
  console.log("fetching uri:", uri);
  return utils.fetchWrapper(uri, {
    method: "GET",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
  });
};

export const getAuditTermsReport = async (token, searchCriteria) => {
  let queryString = "?";
  if (utils.isIterable(searchCriteria)) {
    for (const [key, value] of searchCriteria) {
      if (Array.isArray(value)) {
        for (const item of value) if (item) queryString += `${key}=${item}&`;
      } else queryString += `${key}=${value}&`;
    }
  }
  const uri = `${config.endpoints.backend}/audit/terms${queryString}`;
  console.log("fetching uri:", uri);
  return utils.fetchWrapper(uri, {
    method: "GET",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
  });
};

export const getDisclaimerContent = async (token) => {
  const uri = `${config.endpoints.backend}/settings/consent`;
  return utils.fetchWrapper(uri, {
    method: "GET",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
  });
};
export const getConversationMetadata = async (
  token,
  conversationId,
  recordings
) => {
  const uri = `${config.endpoints.backend}/conversations/${conversationId}/metadata`;
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
    body: JSON.stringify({ recordings }),
  });
};

export const postUserLogin = async (accessToken) => {
  const uri = `${config.endpoints.backend}/users/login`;
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      token: accessToken,
      env: utils.getEnvironment(),
    },
  });
};

export const exportStatus = async (token, searchCriteria) => {
  let queryString = "?";
  if (utils.isIterable(searchCriteria)) {
    for (const [key, value] of searchCriteria) {
      if (Array.isArray(value)) {
        for (const item of value) if (item) queryString += `${key}=${item}&`;
      } else queryString += `${key}=${value}&`;
    }
  }
  const uri = `${config.endpoints.backend}/export/status`;
  console.log("fetching uri:", uri);
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
    body: JSON.stringify({ searchCriteria }),
  });
};

export const downloadRecordings = async (token, payload) =>{
  const uri = `${config.endpoints.backend}/recordings/download`;
  return utils.fetchWrapper(uri, {
    method: "POST",
    headers: {
      token,
      env: utils.getEnvironment(),
    },
    body: JSON.stringify(payload),
  });
}

export default {
  getUsersMe,
  getConversation,
  getConversations,
  getConversationsSettings,
  updateConversationsSettings,
  createUser,
  getUserListAll,
  getUserByID,
  updateUser,
  getUsersListSearch,
  getAuditAccessReport,
  getAuditTermsReport,
  getDisclaimerContent,
  postUserLogin,
  getConversationMetadata,
  exportConversations,
  exportStatus,
  getRecordingURLStatus,
  getIPFXConversations,
  getEvaluationDetails,
  exportIPFXConversations,
  downloadRecordings
};
